<template>
  <v-hover>
    <v-card slot-scope="{ hover }" flat nuxt hover class="border pa-3 mb-3" height="100%" @click="showOffer()">
      <v-img
        :src="offer.image || ''"
        :alt="offer.title"
        aspect-ratio=".8"
      />
      <v-card-title class="pt-2 pb-0 px-0">
        <div class="font-weight-bold">{{ offer.title }}</div>
        <h5 class="text--secondary">{{ offer.company.title }}</h5>
      </v-card-title>
      <v-fade-transition>
        <v-btn
          v-if="hover && $vuetify.breakpoint.smAndUp"
          color="accent"
          class="btn"
          absolute
          depressed
          round
          nuxt
        >
          Bekijk aanbieding
        </v-btn>
      </v-fade-transition>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    offer: {
      type: Object,
      default: () => {
      },
    },
  },
  methods: {
    showOffer() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'

.border
  border: 1px solid grey-light;

.btn
  transform: translate(-50%, -50%);
  top: 42%;
  left: 50%;
</style>
