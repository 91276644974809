<template>
  <div>
    <v-layout :class="$vuetify.breakpoint.smAndDown.isMounted ? 'mb-1': 'mb-3'" align-center wrap>
      <v-flex xs12 md5 lg4>
        <h1 class="font-italic">{{ page.title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <!-- Zoeken en filters -->
      <v-flex xs12 sm4 md3 mt-4>
        <v-text-field id="aanbiedingen-zoeken" v-model="searches" label="Zoeken..." solo single-line class="mb-4"
                      hide-details>
          <v-btn slot="append" large depressed dark class="ma-0" icon>
            <v-icon small color="primary">fas fa-search</v-icon>
          </v-btn>
        </v-text-field>
        <button class="reset-button reset-all" @click="resetAll">Reset alle filters</button>
        <v-expansion-panel v-model="panel" expand class="accordion elevation-0">
          <div class="mt-2" style="width: 100%">
            <v-checkbox
              v-for="(item) in mainAreas"
              :key="item"
              v-model="areas"
              :label="item"
              :value="item"
              style="font-weight: bold;"
              class="mt-2"
              hide-details
            />
          </div>
          <v-expansion-panel-content class="mt-3">
            <strong slot="header">Categorie</strong>
            <button class="reset-button" @click="resetCategories">Reset</button>
              <template v-for="item in options.categories">
              <v-checkbox
                v-if="item !== 'E-learning'"
                :key="item"
                v-model="categories"
                :label="item"
                :value="item"
                hide-details
                class="mt-0"/>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
      <!-- Rechterkant met de items -->
      <v-flex xs12 sm8 offset-md1>
        <div v-if="page.tekst" v-html="page.tekst"/>
        <v-layout>
          <p class="mb-0" role="status">{{ total + (total === 1 ? ' aanbieding' : ' aanbiedingen') }}
            {{ filterActive ? (total === 1 ? 'voldoet' : 'voldoen') + ' aan je filter' : '' }}
          </p>
          <v-spacer/>
          <v-layout :class="$vuetify.breakpoint.smAndDown.isMounted ? 'mt-3': 'mt-0'" column shrink>
            <v-radio-group v-model="step" hide-details class="mt-0">
              <v-layout align-start>
                <v-radio v-for="(item, index) in display" :key="index" :label="item" :value="index"/>
              </v-layout>
            </v-radio-group>
          </v-layout>
        </v-layout>

        <!--       Show as list -->
        <v-container v-if="step === 0" fluid grid-list-md class="pa-0">
          <v-layout row wrap>
            <v-flex v-for="offer in offers" :key="offer.id" xs12 sm6 md4>
              <offer-item :offer="offer" @confirm="showOffer(offer)"/>
            </v-flex>
          </v-layout>
          <v-layout v-if="total > pageLimit" justify-center mt-4>
            <v-pagination v-model="pageNr" :length="lastPage" circle class="pages" color="transparent"/>
          </v-layout>
        </v-container>

        <!--        Show as Map -->
        <template v-else>
          <no-ssr placeholder="Laden...">
            <GmapMap :center="center" :zoom="11" :options="options" style="width: 100%; height: 500px;">
              <gmap-info-window :key="infoOpen.id" :options="infoOptions" :position="infoOpen.position"
                                :opened="!!infoOpen" @closeclick="infoOpen=false">
                <div id="infowindow">
                  <p class="title font-weight-bold">
                    <nuxt-link :to="{name: 'ondernemers-slug', params: {slug: infoOpen.slug}}" class="no-underline">
                      {{ infoOpen.company }}
                    </nuxt-link>
                  </p>
                </div>
              </gmap-info-window>
              <gmap-marker v-for="(marker, index) in markers" :key="index" :label="marker.offers.length.toString()"
                           :position="marker.position" :clickable="true" @click="infoOpen = marker"/>
            </GmapMap>
          </no-ssr>
        </template>

      </v-flex>
    </v-layout>
    <!-- Aanbieding modal -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card class="pa-3">
        <v-card-title class="title font-weight-bold">
          {{ offer.title }}
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs4>
              <v-img
                :src="offer.image || ''"
                :alt="offer.title"
              />
            </v-flex>
            <v-flex xs8 class="px-4">
              <div v-html="offer.description"/>
              <v-divider class="my-2"/>
              <template v-if="offer.company">
                <nuxt-link :to="{name: 'ondernemers-slug', params: {slug: offer.company.slug}}" class="no-underline">
                  <strong>{{ offer.company.title }}</strong>
                </nuxt-link>
                <br>
                <em>{{ offer.company.street }} {{ offer.company.house_number }}</em><br>
                <em>{{ offer.company.postal_code }} {{ offer.company.city }}</em>
              </template>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions class="flex justify-end">
          <v-btn round depressed class="px-5" @click="dialog = false">Sluiten</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import OfferItem from "~/components/offers/OfferItem";

export default {
  auth: false,
  components: {
    OfferItem,
  },
  data() {
    return {
      markers: [],
      markersLoaded: false,
      panel: [false, false, false, false, false],
      step: 0,
      display: ['Lijst', 'Kaart'],

      queued: {},
      infoOpen: true,
      center: {
        lat: 51.9056034,
        lng: 4.3412826,
      },
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -40,
        },
      },
      mainAreas: ['Maassluis', 'Vlaardingen', 'Schiedam'],
      dialog: false,
      offer: () => ({}),
    };
  },
  async asyncData({$axios}) {
    const [options, page] = await Promise.all([
      $axios.get(`/aanbiedingen/options`),
      $axios.get(`/page?segments=pas`),
    ]);

    return {
      options: options.data,
      page: page.data.data,
    };
  },
  head() {
    return {
      title: this.page.meta.title,
      meta: [
        this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
  computed: {
    lastPage() {
      return Math.ceil(this.total / this.pageLimit);
    },
    ...mapState({
      filter: state => state.offers.filter,
      settings: state => state.settings,
      offers: state => state.offers.items,
      total: state => state.offers.total,
      pageLimit: state => state.offers.pageLimit,
      filterActive: state => state.offers.filterActive,
    }),
    areas: {
      set(areas) {
        if (areas.length) {
          this.$router.push({path: this.$route.path, query: {...this.$route.query, areas: areas.join('|')}})
        } else {
          this.$router.push({path: this.$route.path, query: {...this.$route.query, areas: undefined}})
        }
        this.pageNr = 1;
        this.$store.commit('offers/SET_AREAS', areas);
      },
      get() {
        return this.filter.areas;
      },
    },
    categories: {
      set(categories) {
        if (categories.length) {
          this.$router.push({path: this.$route.path, query: {...this.$route.query, categories: categories.join('|')}})
        } else {
          this.$router.push({path: this.$route.path, query: {...this.$route.query, categories: undefined}})
        }
        this.pageNr = 1;
        this.$store.commit('offers/SET_CATEGORIES', categories);
      },
      get() {
        return this.filter.categories;
      },
    },
    searches: {
      set(searches) {
        if (searches.length) {
          this.$router.push({path: this.$route.path, query: {...this.$route.query, searches: searches}})
        } else {
          this.$router.push({path: this.$route.path, query: {...this.$route.query, searches: undefined}})
        }
        if (this.queued.searches) {
          clearTimeout(this.queued.searches);
        }

        this.queued.searches = setTimeout(
          () => {
            this.pageNr = 1;
            this.$store.commit('offers/SET_SEARCHES', searches)
          },
          250
        );
      },
      get() {
        return this.filter.searches;
      },
    },
    pageNr: {
      set(page) {
        if (page > 1) {
          this.$router.push({path: this.$route.path, query: {...this.$route.query, page: page}})
        } else {
          this.$router.push({path: this.$route.path, query: {...this.$route.query, page: undefined}})
        }
        this.$store.commit('offers/SET_PAGE_NR', page);
      },
      get() {
        return this.filter.page;
      },
    },
  },
  watch: {
    filter: {
      async handler() {
        await this.$store.dispatch('offers/getOffers');
      },
      deep: true,
    },

    async step(value) {
      if (value === 1 && !this.markersLoaded) {
        this.markersLoaded = true;

        try {
          const locations = await this.$axios.get(`/aanbiedingen/locations`);

          this.markers = locations.data;
        } catch (e) {
          this.markersLoaded = false;

          console.error(e);
        }
      }
    },
  },
  async fetch({store, error}) {
    try {
      if (!store.state.offers.items.length) {
        await store.dispatch('offers/getOffers');
      }

    } catch (e) {
      error({statusCode: 410, message: e.message});
    }
  },
  mounted() {
    if (this.$route.query.areas) {
      const areas = decodeURI(this.$route.query.areas);
      this.areas = areas.split('|');
    }
    if (this.$route.query.page) {
      this.pageNr = this.$route.query.page;
    }
  },
  methods: {
    resetAll() {
      this.resetAreas();
      this.resetCategories();
      this.$router.push({path: this.$route.path, query: {}})
    },
    resetAreas() {
      this.areas = [];
    },
    resetCategories() {
      this.categories = [];
    },
    showOffer(offer) {
      this.dialog = true;
      this.offer = offer;
    },
  },
}
</script>

<!-- styling from geschenk/index.vue -->
<!--<style lang="stylus" scoped>-->
<!--.min-mx-->
<!--  margin: 0 -8px;-->

<!--.bg-image-->
<!--  position relative-->
<!--  padding 4rem 0-->
<!--  background-size cover-->
<!--  z-index 1-->
<!--</style>-->

<!-- styling from academie/index.vue -->
<style scoped lang="stylus">
@import '~assets/style/colors'

.accordion >>>
  .v-expansion-panel__header, .v-expansion-panel__header__icon i
    color primary !important

  .v-expansion-panel__container
    border none !important

  .v-expansion-panel__header
    padding 0

.v-input--slider
  margin-top 0

.v-slider__thumb
  width 30px
  height 30px
  cursor pointer

.v-divider
  margin 20px 0

.text__overflow
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  display block

.grey__hover:hover
  background grey-light

.img-round
  border-radius 50%

.reset-button
  display block
  color darkred
  text-decoration underline
  text-align right
  font-size 80%
  width 100%

</style>
